<template>
    <div class="p-2">
        <div class="d-flex align-items-center mb-2">
            <b-breadcrumb class="mb-0 flex-fill" :items="breadcrumbs"></b-breadcrumb>
            <div class="flex-wrap">

            </div>
        </div>
        <div class="row no-gutters">
            <div class="col-3 pr-2">
                <b-card>
                    <filters-loader endpoint="billing/transaction-filters"
                                    v-on:filters_updated="(filters) => { filtersUpdated(filters)}"/>
                </b-card>
            </div>
            <div class="col-9">
                <paginated-result-loader endpoint="billing/transactions" cell-type="transactions"
                                         :filters="request"></paginated-result-loader>
            </div>
        </div>
        <b-modal :no-close-on-backdrop="true" :hide-header=true :hide-footer=true v-model="showModal">
            <div class="text-right position-absolute" style="right: 10px; top:10px;">
                <a class="btn btn-sm text-primary" @click="() => showModal = false"><b-icon-x-circle></b-icon-x-circle></a>
            </div>
            <router-view></router-view>
        </b-modal>
    </div>
</template>

<script>
    import FiltersLoader from "../../../components/FiltersLoader";
    import PaginatedResultLoader from "../../../components/PaginatedResultLoader";
    import _ from 'lodash'

    export default {
        name: "transactions",
        components: {PaginatedResultLoader, FiltersLoader},
        data: function () {
            return {
                showModal: false,
                request: {},
                paginatedResponse: {},
                breadcrumbs: [
                    {
                        text: 'Dashboard',
                        to: {'name': 'dashboard'}
                    },
                    {
                        text: 'Transactions',
                        active: true
                    }
                ]
            }
        },
        watch: {
            $route: {
                immediate: true,
                handler: function (newVal, oldVal) {
                    this.showModal = newVal.meta && newVal.meta.showModal;
                    if(newVal.params.data_updated !== undefined) {
                        this.request = _.clone(this.request)
                    }
                    if(newVal !== oldVal && !newVal) {
                        this.request = _.clone(this.request)
                    }
                }
            },
            showModal: function (newVal, oldVal) {
                if (!newVal) {
                    if(this.$router.currentRoute.name !== 'transactions') {
                        this.$router.replace({'name': 'transactions', params : {'data_updated' : true}}).catch((err) => {});
                    }
                }
                if(newVal !== oldVal && !newVal) {
                    this.request = _.clone(this.request)
                }
            }
        },
        methods: {
            filtersUpdated(filters) {
                this.request = filters;
            }
        }

    }
</script>
